import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import config from '../website-config';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
  NavBarBackground,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

import { GatsbyImage } from 'gatsby-plugin-image';
import { getSrc } from 'gatsby-plugin-image';
import { lighten } from 'polished';

import { SafeText } from '../components/safeEmail';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const test = css`
  display: flex;
  align-items: center;
  flex-direction: row;
  @media (max-width: 1100px) {
    display: block;
  }
`;

const AuthorImgStyle = css`
  display: flex !important;
  width: 10em !important;
  height: auto;
  margin-left: -0.5em !important;
  margin-right: 1em !important;
  /* background: color(var(--lightgrey) l(+10%)); */
  background: ${lighten('0.1', colors.lightgrey)};
  border-radius: 15%;
  object-fit: cover;
  left: 0;

  @media (prefers-color-scheme: dark) {
    background: ${colors.darkmode};
  }
`;

interface AuthorImgProps {
  authorImg?: {
    childImageSharp: {
      fixed: GatsbyImage;
    };
  };
}

const AuthorImg = () => (
  <StaticQuery
    query={graphql`
      query AuthorImg {
        authorImg: file(relativePath: { eq: "img/lari.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 500, layout: FIXED)
          }
        }
      }
    `}
    render={(data: AuthorImgProps) => {
      if (!data.authorImg) {
        return <p>ffffffff</p>;
      }

      return (
        <img
          className="author-img-logo"
          css={AuthorImgStyle}
          src={getSrc(data.authorImg.childImageSharp.gatsbyImageData)}
          alt={config.title}
        />
      );
    }}
  />
);

const About: React.FC = ({ props }) => {
  const jsonld_about = {
    '@context': 'https://schema.org',
    '@type': 'AboutPage',
    lastReviewed: '2021-01-22T20:06:55.979Z',
    description: 'Here you can find information about the website machinelearningcompass.com.',
    inLanguage: config.lang,
    url: config.siteUrl + '/about/',
    name: 'About',
  };

  return (
    <IndexLayout>
      <Helmet>
        <html lang={config.lang} />
        <title>About</title>

        <meta name="description" content="About this website" />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="about" />
        <meta property="og:title" content="About" />
        <meta property="og:description" content="About this website" />
        <meta property="og:url" content={config.siteUrl + '/about'} />
        <meta property="article:published_time" content={'2021-01-22T20:06:55.979Z'} />
        <script type="application/ld+json">{JSON.stringify(jsonld_about)}</script>
      </Helmet>

      <Wrapper css={PageTemplate}>
        <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
          <div css={[outer, SiteNavMain, NavBarBackground]}>
            <div css={inner}>
              <SiteNav isHome={true} />
            </div>
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <article className="post page" css={[PostFull, NoImage]}>
              <PostFullHeader className="post-full-header">
                <PostFullTitle className="post-full-title">About</PostFullTitle>
              </PostFullHeader>

              <PostFullContent className="post-full-content">
                <div className="post-content">
                  <div css={test}>
                    <AuthorImg />
                    <div>
                      <h4>Hello there!</h4>
                      My name is Lari, I love learning and teaching complex topics, especially in
                      the field of machine learning. In my free time, I run this website, which is
                      designed to make you understand machine learning more clearly and intuitively,
                      and most importantly, make it enjoyable and not-so-difficult.
                    </div>
                  </div>
                  <h5>Who am I?</h5>
                  <p>
                    If you are learning machine learning, data science, or really anything that
                    works with data or intelligent systems, you have come to the right place! My
                    name is Lari and I am a computer science student. I have worked multiple years
                    with data and machine learning as a research assistant, in my free time and I
                    have also been very passionate about competitions. I was a national finalist at
                    the german national competition for AI (or BWKI="Bundeswettbewerb Künstliche
                    Intelligenz") in 2019 and since 2020 I have been working together with the BWKI
                    to create free learning resources for people interested in machine learning and
                    artificial intelligence. With the help of some truly smart and motivated people
                    I have created an open source course on convolutional neural networks and
                    co-organized a machine learning hackathon for the BWKI to show more young
                    students in germany how beautiful and fascinating machine learning can be.
                  </p>

                  <h5>What is this website?</h5>
                  <p>
                    I really enjoy teaching and sharing knowledge. To show more people how amazing
                    and how not-so-complicated-as-you-might-have-thought artificial intelligence and
                    machine learning is, I created the machine learning compass. On this website, I
                    will create easy to read explanations with real-code-examples for popular
                    machine learning algorithms, methods and best practices. You will find clear
                    explanations, descriptive visualizations, and working code for Keras,
                    TensorFlow, PyTorch as well as Scikit-learn. I know first-handedly how difficult
                    it can be to navigate the field of machine learning, because I felt that way
                    just a few years ago. I don't want to scare you away from machine learning. I
                    don't want to bombard you with mathematical expressions to make you hate machine
                    learning for the rest of your life. I want to make you understand machine
                    learning intuitively, I want to make you be able to enjoy machine learning
                    (again). Of course, I will also talk about the math if it is relevant to the
                    given topic. Of course, I will also try to explain some topics which are complex
                    by nature. But I always want you to have the best learning experience possible,
                    and I will always provide clear and detailed explanations to everything I write
                    about on this website. I won't just write "and now we define our convolutional
                    layer", I will precisely tell you what we are doing and what we are doing, so
                    that you learn not to follow my guides, but you learn to create the things I
                    present, and you learn to create your own amazing machine learning applications
                    and projects. If this sounds good to you, I highly recommend you come back to
                    this website whenever you hear a term such as "Support Vector Machine" or "L2
                    regularization" that you are not familiar with.
                  </p>

                  {/*<h5>How do I use your website?</h5>
                <p>
                It's simple. You come across a term you are not familiar with,
                say f.e. "Gradient Boosting". You go to
                <a href="https://machinelearningcompass.com">machinelearningcompass.com</a>,
                you click on "Search" in the navigation menu and you type in your term you are
                not familiar with. You get exactly the one explanation you wanted to see.
                You read the explanation and understand what you wanted to understand. That's it.
                In the future, I might add complete tutorial series or project walkthroughs
                to this website, but for now this is how you use the machine learning compass.
                </p>

                <h5>Is this free? Are you trying to sell me a subscription or something?</h5>
                <p>
                Yes. Yes, this is completely free. I am not trying to sell you a subscription.
                You can read as many posts as you like, as often as you want, whenever you want,
                without having to create a account. Even if, in the future, I will have to monetize
                this website somehow to keep it alive, the core features, such as reading articles
                or searching the website will *always* remain free to use, promise.
                
                </p>
                <h5>Why should I read your explanations?</h5>
                <p>
                If you read the first section of this page and said to yourself,
                "he's only a cs student? He doesn't know what he's talking about."
                and you do not think I am qualified enough to provide these explanations
                for free on the internet and you would rather read from someone who
                has a PhD or some other qualification I do not posses and you are certain
                in your decision, I will not try to convince you otherwise.
                If you feel like you need a person with a specific qualification to learn
                effectively, then I am probably not the right person to read articles from.
                If you go now, I am truly sorry to not have expanded your knowledge of 
                machine learning and not have helped you out.

                If you, on the other hand, are questioning, why you should read my explanations
                instead of the explanations of some person with a PhD, I can try to provide
                you with a few reasons as to why I have made exactly this decision in my own
                learning process multiple times. 

                I have taught a lot in the past. I am not a teacher, but I always
                had very good grades. Very often I helped fellow class mates
                or commilitones to understand mathematical topics. I have received
                highly positive responses and I am confident in my ability to help
                you understand machine learning just a little bit better, which is the reason
                I started this website; to help you understand machine learning better.

                Now, I have read a lot about machine learning on the web, I mean a *lot* lot.
                Not rarely have I found myself in the situation that someone who is just
                learning a topic for the first time or someone who is just really passionate
                about machine learning, made me understand a certain concept much more clearly
                and made it much more enjoyable for me, than someone who has rigorously studied
                the field for many, many years. Why did I feel this way?
                One reason that comes to my mind is that understanding a topic
                well does not automatically make you a able to teach said topic well.
                On the other hand, being able to teach a topic very well *usually* means
                that you have understood it well. 
                Another reason is that, in my opinion, people who are just learning something
                for the first time usually understand the struggles of someone trying
                to learn that same thing for the first time as well, a lot better, because
                they are almost in the same boat. Because I have learned machine learning,
                on my own, I know how it feels to not understand x or to not get why
                x has to be done. Judging the difficulty of something is difficult,
                especially with topics as complex as machine learning. Sometimes
                you can feel as if your instructor thinks that xy really is the most
                trivial thing in the world, but you think otherwise.
                
                These are some of the reasons why I, personally, sometimes prefer
                reading from someone who has just finally managed to really understand
                a certain topic, instead of from someone who has done xy for 10 years already.

                Does this mean that you should not listen to people with PhDs or advanced qualifications?
                Absolutely not. Everyone ist different. Just because something worked for me, it does
                not mean that it has to work for you. Also, some people are not only have a PhD
                or an advanced qualification, but also happen to be great teachers. What I am trying
                to say is: Don't judge a person's teaching abilities by whether or not they have x.
                Listen to them and judge them yourself. You are the only person that knows how you
                can learn optimally. I am not saying that I am the best teacher in the world. But I
                may be able to help you understand machine learning just a little bit better. And that
                is all I want.
                </p>*/}

                  <h5>Can I contact you?</h5>
                  <p>
                    Yes! If you have any questions or you just want to reach out, feel free to shoot
                    me an email over at:
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginRight: '1em' }}></span>
                      <SafeText parts={['lari', 'machinelearningcompass', 'com']} />
                    </div>
                    I would be more than happy to talk to you!
                  </p>
                </div>
              </PostFullContent>
            </article>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default About;
